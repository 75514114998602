import React from 'react'
import ContactUs from '../ContactUs'

import Sec1 from './Sec1'
import Sec2 from './Sec2'
import Sec3 from './Sec3'
import Sec4 from './Sec4'


function About() {
  return (
    <div>
     
    <Sec1/>
    <Sec2/>
    <Sec3/>
    <Sec4/>
    <ContactUs/>
    </div>
  )
}

export default About