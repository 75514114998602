import React from 'react'
import {Link} from 'react-router-dom'

function Sec4() {
  return (
    <div className='sec4'>
       
        <Link> <h1>SO, Are You Branded Much Yet?</h1></Link>
    </div>
  )
}

export default Sec4